// src/components/IntroEnemy.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from '../css/modules/IntroEnemy.module.css';
import EnergyBar from './EnergyBar';
import Typewriter from './Typewriter';
import AnalogCounter from './AnalogCounter';
import { useUser } from './UserContext';

const IntroEnemy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  const defaultUserData = {
    image: 'my-photo',
    name: 'Gracz',
    energy: 100,
    score: 550,
    ranking: 0,
    level: 0,
    artefacts: [],
  };

  const passedUserData = location.state?.userData;
  const [userData, setUserData] = useState(user || passedUserData || defaultUserData);

  const [enemy, setEnemy] = useState(null);
  const [loadingEnemy, setLoadingEnemy] = useState(true);
  const [errorEnemy, setErrorEnemy] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const WEBSITE = process.env.REACT_APP_WEBSITE;

  // 🔁 Funkcja do pobierania przeciwnika (można wywołać ręcznie)
  const fetchEnemyData = async (excludeSlug = '') => {
    setLoadingEnemy(true);
    try {
      const url = `${API_BASE_URL}/cyber/get_random_enemy?website=${WEBSITE}&exclude=${excludeSlug}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Błąd sieciowy: nie udało się pobrać danych przeciwnika');
      }

      const data = await response.json();
      const imageUrl = `/enemies/${data.slug}.webp`;

      setEnemy({
        image: imageUrl,
        name: data.name,
        health: data.health,
        slug: data.slug,
        strength: data.strength,
        time: data.time,
        motto: data.motto,
      });
    } catch (err) {
      console.error('Błąd podczas pobierania danych przeciwnika:', err);
      setErrorEnemy(err.message);
    } finally {
      setLoadingEnemy(false);
    }
  };

  // Pierwsze pobranie przeciwnika
  useEffect(() => {
    fetchEnemyData();
  }, []);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    const updateEnergy = async () => {
      if (userData && userData.energy < 1) {
        const updatedUserData = { ...userData, energy: 10 };
        setUserData(updatedUserData);
        const updatedData = { energy: 10 };
        try {
          const token = localStorage.getItem('access_token');
          await fetch(`${API_BASE_URL}/cyber/user_info`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          });
        } catch (error) {
          console.error('Error updating user info in DB:', error);
        }
      }
    };
    updateEnergy();
  }, [userData, API_BASE_URL]);

  const handleContinue = () => {
    if (enemy) {
      navigate(`/quiz/${enemy.slug}`, { state: { enemyData: enemy, userData } });
    }
  };

  const handleShop = () => {
    navigate('/shop', { state: { score: userData.score } });
  };

  const handleEnemyClick = () => {
    const currentSlug = enemy?.slug || '';
    fetchEnemyData(currentSlug); // ← przekażemy aktualnego przeciwnika jako "exclude"
  };

  if (loadingEnemy) {
    return (
      <div className={styles.cyberpunkContainer}>
        Ładowanie danych...
      </div>
    );
  }

  if (errorEnemy) {
    return (
      <div className={styles.cyberpunkContainer}>
        Błąd: {errorEnemy}
      </div>
    );
  }

  return (
    <div className={styles.cyberpunkContainer}>
      <div className={styles.fightContainer}>
        <div className={styles.enemyBox}>
        <div className={styles.enemyImageWrapper}>
          <img
            src={enemy.image}
            alt={enemy.name}
            className={styles.cyberImage}
            onClick={handleEnemyClick} // ← kliknięcie odświeża
          />
          <button className={styles.refreshIcon} onClick={handleEnemyClick} title="Wylosuj nowego przeciwnika">
            🔄
          </button>
        </div>
          <h2 className={styles.enemyName}>
            <Typewriter text={enemy.name} typingSpeed={100} />
          </h2>
          {enemy.motto && <div className={styles.motto}>{enemy.motto}</div>}
          <div className={styles.stats}>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Siła:</strong></span>
              <span className={styles.statValue}><AnalogCounter value={enemy.strength || 0} /></span>
            </div>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Energia:</strong></span>
              <span className={styles.statValue}><EnergyBar current={enemy.health} /></span>
            </div>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Szybkość:</strong></span>
              <span className={styles.statValue}><AnalogCounter value={enemy.time || 0} /></span>
            </div>
          </div>
        </div>

        <div className={styles.vsContainer}>
          <span className={styles.vsText}>VS</span>
          <button className={styles.vsStartButton} onClick={handleContinue}>
            WALCZ!
          </button>
        </div>

        <div className={styles.userBox}>
          <img src={`/${userData.image}.webp`} alt={userData.name} className={styles.cyberImage} />
          <h2 className={styles.userName}>{userData.name}</h2>
          <div className={styles.stats}>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Kasa:</strong></span>
              <span className={styles.statValue}><AnalogCounter value={userData.score || 0} /></span>
            </div>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Energia:</strong></span>
              <span className={styles.statValue}><EnergyBar current={userData.energy} /></span>
            </div>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Level:</strong></span>
              <span className={styles.statValue}><AnalogCounter value={userData.level || 0} /></span>
            </div>
            <div className={styles.statRow}>
              <span className={styles.statLabel}><strong>Dobre odp:</strong></span>
              <span className={styles.statValue}><AnalogCounter value={userData.answered_correct || 0} /></span>
            </div>
          </div>
          <div className={styles.shopButtonContainer}>
            <button className={styles.shopButton} onClick={handleShop}>
              Sklep
            </button>
          </div>
          {userData.artefacts && (
            <div className={styles.artefactsContainer}>
              <div className={styles.artefactsBox}>
                {Array.from({ length: 4 }, (_, index) => {
                  const artifact = userData.artefacts[index];
                  return (
                    <div key={index} className={styles.artifactSlot}>
                      {artifact ? (
                        <img
                          src={`/artefacts/${artifact}.webp`}
                          alt={`Artefakt ${index + 1}`}
                          className={styles.artifactImage}
                        />
                      ) : (
                        <div className={styles.emptyArtifact} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.startBattleButton} onClick={handleContinue}>
          START
        </button>
      </div>
    </div>
  );
};

export default IntroEnemy;
