// src/TieScreen.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import styles from '../css/modules/CongratsScreen.module.css';
import Typewriter from './Typewriter';
import { useUser } from './UserContext';

const defaultUserData = {
  image: '/my-photo.webp',
  name: 'Player',
  energy: 100,
  score: 0,
  ranking: 0,
  level: 1,
  artefacts: [],
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TieScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enemySlug } = useParams(); // Expects URL like "/:enemySlug/tie"
  const { user } = useUser();

  const [enemyData, setEnemyData] = useState(location.state?.enemy || null);
  const [loadingEnemy, setLoadingEnemy] = useState(false);

  useEffect(() => {
    if (!enemyData && enemySlug) {
      setLoadingEnemy(true);
      fetch(`${API_BASE_URL}/cyber/get_enemy_by_slug`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ slug: enemySlug }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error fetching enemy data');
          }
          return response.json();
        })
        .then((data) => {
          setEnemyData(data);
          setLoadingEnemy(false);
        })
        .catch((error) => {
          console.error('Error while fetching enemy data:', error);
          setLoadingEnemy(false);
        });
    }
  }, [enemyData, enemySlug]);

  const finalUserData = user || defaultUserData;
  const finalScore = finalUserData.score || 0;

  let rawImage = finalUserData.image || 'my-photo.webp';
  if (!rawImage.startsWith('/')) {
    rawImage = '/' + rawImage;
  }
  if (!rawImage.endsWith('.webp')) {
    rawImage = rawImage + '.webp';
  }
  const finalUserImage = rawImage;

  if (loadingEnemy) {
    return (
      <div className={styles.congratsContainer}>
        Loading enemy data...
      </div>
    );
  }

  const finalEnemy = enemyData || { slug: enemySlug || 'default', name: 'Enemy' };

  const handleContinue = () => {
    navigate('/intro', { state: { userData: finalUserData } });
  };

  return (
    <div className={styles.congratsContainer}>
      <img 
        src={`/enemies/${finalEnemy.slug}.webp`} 
        alt={`Enemy: ${finalEnemy.name}`} 
        className={styles.enemyImageTie} 
      />
      <h1 className={styles.congratsMessage}>
        <Typewriter 
          text={`REMIS! Stoczyliście zacięty bój ale nikt nie wygrał!`} 
          typingSpeed={100} 
        />
      </h1>
      <p className={styles.scoreMessage}>Twoje punkty: {finalScore} $</p>
      
      <div className={styles.buttonContainer}>
        <button 
          className={styles.continueButton} 
          onClick={handleContinue}
        >
          Try Again
        </button>
        <Link 
          to="/shop" 
          className={styles.continueButton}
        >
          Shop
        </Link>
      </div>
    </div>
  );
};

export default TieScreen;
