// src/DefeatScreen.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../css/modules/DefeatScreen.module.css';
import Typewriter from './Typewriter';
import { useUser } from './UserContext';
import { useUserInfo } from '../hooks/useUserInfo';

const DefeatScreen = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { userInfo, loading } = useUserInfo();

  // Lokalny stan, który określa, czy mamy już dane użytkownika
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!loading) {
      setReady(true);
    }
  }, [loading]);

  if (loading || !ready) return <div>Ładowanie danych użytkownika...</div>;

  // Pobieramy dane użytkownika: z useUserInfo, globalnego kontekstu lub ustawiamy domyślne wartości.
  const finalUserData = userInfo || user || { image: 'my-photo.webp', name: 'Gracz', score: 0, energy: 100 };
  const finalScore = finalUserData.score || 0;
  const finalUserName = finalUserData.name || 'Gracz';

  // Modyfikujemy właściwość "image": dodajemy "/" na początku i ".webp" na końcu, jeśli potrzeba.
  let rawImage = finalUserData.image || 'my-photo.webp';
  if (!rawImage.startsWith('/')) {
    rawImage = '/' + rawImage;
  }
  if (!rawImage.endsWith('.webp')) {
    rawImage = rawImage + '.webp';
  }
  const finalUserImage = rawImage;

  // Losujemy tło na podstawie liczby dostępnych obrazów (ustawionej w zmiennej środowiskowej)
  const bgImagesCount = Number(process.env.REACT_APP_BACKGROUND_IMAGES_SIZE) || 5;
  const randomBgNumber = Math.floor(Math.random() * bgImagesCount) + 1;
  const backgroundImageUrl = `/backgrounds/${randomBgNumber}.webp`;

  const containerStyle = {
    backgroundImage: `linear-gradient(135deg, rgba(13,13,13,0.7), rgba(26,26,26,0.7)), url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const handleContinue = () => {
    if (user) {
      navigate('/intro');
    } else {
      navigate('/cennik');
    }
  };

  return (
    // Używamy atrybutu key zależnego od finalUserData.score, aby wymusić remount, gdy wynik się zmieni.
    <div key={finalUserData.score} className={styles.defeatContainer} style={containerStyle}>
      <img
        src={finalUserImage}
        alt={`Gracz: ${finalUserName}`}
        className={styles.userImage}
      />
      <h1 className={styles.congratsMessage}>
        <Typewriter text="Nie poddawaj się!" typingSpeed={100} />
      </h1>
      <p className={styles.congratsMessage}>
        Tym razem zostałeś pokonany, ale to nie koniec walki!
      </p>
      <p>Odkryj sekrety przyszłości – zarejestruj się i rozpocznij grę!</p>
      <p className={styles.scoreMessage}>Twój wynik: {finalScore} punktów</p>
      <div className={styles.buttonContainer}>
        <button className={styles.continueButton} onClick={handleContinue}>
          {user ? 'Spróbuj ponownie' : 'KUP DOSTĘP'}
        </button>
        <Link to="/shop" state={{ score: finalScore }} className={styles.continueButton}>
          Sklep
        </Link>
      </div>
      {/* Jeśli użytkownik nie jest zalogowany, wyświetlamy dodatkowe CTA do logowania */}
      {!user && (
        <div className={styles.loginContainer}>
          <p>Masz już konto? Zaloguj się, aby zachować postępy!</p>
        </div>
      )}
    </div>
  );
};

export default DefeatScreen;
